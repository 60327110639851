import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabletop from 'tabletop';
import slugify from 'slugify';
import Loading from '../Loading';
import ReportTable from './ReportTable';

export class ReportSpreadsheet extends Component {

  static propTypes = {
    sheet_id: PropTypes.string.isRequired
  }

  state = {
    isLoaded: false,
    sheet_id: this.props.sheet_id,
    sheet_url: this.props.sheet_url,
    sheet_data: [],
    columns: [],
  }

  componentWillMount(){
    Tabletop.init({ 
      key: this.state.sheet_id,
      callback: data => {

        //Extract first row for column data
        const firstRow = data[0];
        //Get keys 
        const _headers = Object.keys(firstRow);

        const columns = _headers.map( (col, i) => {

          let colKey = slugify(col.toLowerCase())
          let frozenCol = false;
          let siteDomain = process.env.REACT_APP_CLIENT_URL;
          let _formatter = undefined;
          let _width = undefined;
          let _filterable = false;

          //Custom format for page-url col (relative path)
          if(i === 0 && colKey === 'page-url'){
            frozenCol = true;
            _width = 200;
            _filterable = true;
            _formatter = ({value}) => {
              return(
                <a href={siteDomain+value} className="cell-link" target='_blank' rel="noopener noreferrer">{value}</a>
              )
            }
          }

          //Custom format for page col (absolute path)
          if(i === 0 && colKey === 'page'){
            frozenCol = true;
            _width = 200;
            _filterable = true;
            _formatter = ({value}) => {
              return(
                <a href={value} className="cell-link" target='_blank' rel="noopener noreferrer">{value}</a>
              )
            }
          }

          //Custom format for source-url col
          if(i === 0 && colKey === 'source-url'){
            frozenCol = true;
            _width = 150;
            _formatter = ({value}) => {
              return(
                <a href={value} className="cell-link" target='_blank' rel="noopener noreferrer">{value}</a>
              )
            }
          }

          return {
            name: col,
            width: _width,
            key: colKey,
            editable: false,
            resizable: true,
            sortable: true,
            frozen: frozenCol,
            formatter: _formatter,
            filterable: _filterable
          }
        })

        /*
        //Add id column for debugging
        //Create key column
        const idCol = {
          name: '_id',
          key: '_id',
          resizable: true,
          sortable: true,
        }
        //Add _id as first col
        columns.unshift(idCol);
        */
        

        //Format data for table
        const sheet_data = data.map( (row, i) => {
          var key, keys = Object.keys(row);
          var n = keys.length;
          var newobj={ _id : i }
          while (n--) {
            key = keys[n];
            newobj[slugify(key.toLowerCase())] = row[key];
          }
          return newobj;
        })

        this.setState({
          sheet_data: sheet_data,
          columns: columns,
          isLoaded: true
        })
      },
      simpleSheet: true 
    })
  }


  render() {
    const { sheet_data, sheet_url, columns, isLoaded} = this.state;
    if(isLoaded){
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col" style={{display: 'flex', justifyContent: 'flex-end', fontSize: '14px', padding: '10px'}}>
              <a href={sheet_url} target="_blank" rel="noopener noreferrer">View in Google Sheets</a>
            </div>
          </div>
          <div className="row">
            <div className="col" style={{fontSize: "12px"}}>
              <ReportTable data={sheet_data} columns={columns}/>
            </div>
          </div>    
        </div>
      )
    }

    return <Loading />
  }
}

export default ReportSpreadsheet
