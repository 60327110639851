import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading';
import ReportSpreadsheet from './ReportSpreadsheet';
import ImgPreview from '../ImgPreview';
import pointing_finger_left from '../../img/pointing-finger-left.svg';

export class Report extends Component {
  
  state = {
    report: {},
    isLoaded: false,
    slug: this.props.match.params.slug,
    id: null
  }

  // encode(decode) html text into html entity
  decodeHtmlEntity(str) {
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  };

  componentDidMount() {
    const getReport = axios.get(`${process.env.REACT_APP_WP_API}/wp-json/wp/v2/reports?_embed&slug=${this.state.slug}`);

    Promise.all([getReport])
      .then( res => {
        this.setState({
          report: res[0].data[0],
          isLoaded: true
        });
      })
      .catch( err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.match.params.slug !== this.state.slug ){
      this.setState({isLoaded: false});
      const updateReport = axios.get(`${process.env.REACT_APP_WP_API}/wp-json/wp/v2/reports?_embed&slug=${nextProps.match.params.slug}`);

      Promise.all([updateReport])
      .then( res => {
        this.setState({
          report: res[0].data[0],
          isLoaded: true,
          slug: nextProps.match.params.slug 
        });
      })
      .catch( err => console.log(err));
    }
  }

  style = {
    headerRow: {
      backgroundColor: '#323c43',
    },
    headerText: {
      color: '#fff',
      fontSize: '3rem',
      fontWeight: '900',
      letterSpacing: '1px'
    }
  }
  

  render() {
    const {isLoaded } = this.state;

    if(isLoaded){
      const {report } = this.state;
      const featuredImg = report._embedded['wp:featuredmedia'];

      return (
        <div className="container-fluid px-0">
          <div className="row p-5" style={this.style.headerRow}>
            <h2 style={this.style.headerText}>{ this.decodeHtmlEntity(report.title.rendered) }</h2>
          </div>
          <div className="container">

            <div className="row">
              <div className="col">
                <div className="col mx-auto p-5 wp-content" 
                  dangerouslySetInnerHTML={{ __html: report.content.rendered }}
                  style={{maxWidth: '800px', fontSize: '1.2rem', fontWeight:'300'}}
                ></div>
              </div>
            </div>
          </div>
          {featuredImg && <ImgPreview img={featuredImg[0]}/>}
          {report.acf.spreadsheet_id && <ReportSpreadsheet sheet_id={report.acf.spreadsheet_id} sheet_url={report.acf.spreadsheet_url}/>}

          <div className="row" style={{display: 'flex', flexDirection: 'column', textAlign: 'center', margin: '50px'}}>
            <Link to={`/section/${report.acf.parent_section[0]}`}>
                <div>
                  <img src={pointing_finger_left} alt="previous section" style={{ width: '300px'}} />
                </div>   
                <p style={{ fontSize: '1.5rem', fontWeight: '900' }} >Return to {report.acf.parent_section[0]} section</p>
            </Link>
          </div>

        </div>
      )
    }

    return ( <Loading />)
  }
}

export default Report
